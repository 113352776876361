<template>
  <div>
    <div class="flex items-center justify-between">
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.attestations", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.learning_attestations") }}
        </VHint>
      </VTitle>

      <VDropdown
        dropdown-menu-class="w-52"
        :selected="selectedStatus"
        :options="statusOptions"
        @click="selectedStatus = $event"
      >
        <template #option="{ option }">
          <div class="cursor-pointer p-2 flex justify-between items-center">
            <div class="truncate w-2/3">
              {{ option.text }}
            </div>

            <div
              v-if="option.value !== 'all'"
              class="rounded-full w-3 h-3"
              :style="{ 'background-color': getRGBStatusColor(option.value) }"
            />
          </div>
        </template>
      </VDropdown>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert v-else-if="data.length === 0" :text="$t('app.no_results_found')" />

    <div v-else>
      <div class="test grid gap-8">
        <div v-for="attestation in data" :key="attestation.id">
          <AttestationCard
            :resource="attestation"
            @click="onClickRedirectToAttestation"
            @refresh="getData"
          />
        </div>
      </div>

      <FullPagination
        :pagination="pagination"
        @update:per_page="onUpdatePerPage"
        @update:current_page="onUpdateCurrentPage"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useColor from "@/composables/useColor";
import useOptions from "@/composables/useOptions";
import usePaginate from "@/composables/usePaginate";
// Components
import AttestationCard from "./AttestationCard";
import VTitle from "@/components/VTitle";
import VHint from "@/components/VHint";
import FullPagination from "@/components/tables/FullPagination";
import VDropdown from "@/components/VDropdown";
import VAlert from "@/components/VAlert";

export default {
  components: {
    VTitle,
    VHint,
    AttestationCard,
    FullPagination,
    VDropdown,
    VAlert
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Composables
    const { read, pagination, isLoading, data } = useReadMultiple();
    const { ALL_OPTIONS } = useOptions();
    const { getRGBStatusColor } = useColor();

    // Data
    const selectedStatus = ref("all");

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.attestations", 2)} - ${t("app.learning")}`;
    const statusOptions = [
      {
        text: t("app.all_statuses"),
        value: "all"
      },
      ALL_OPTIONS.PAUSED,
      ALL_OPTIONS.PENDING,
      ALL_OPTIONS.PENDING_APPROVAL,
      ALL_OPTIONS.COMPLETED,
      ALL_OPTIONS.REQUESTED,
      ALL_OPTIONS.WITHDRAWN,
      ALL_OPTIONS.CANCELLED
    ];

    // Methods
    const getData = async () => {
      let queryParams = {};

      if (selectedStatus.value !== "all") {
        queryParams = {
          ro_f: JSON.stringify([
            {
              f: "status",
              c: "EQUAL",
              v: [selectedStatus.value]
            }
          ])
        };
      }

      await read({
        endpoint: "learning.attestations",
        queryParams
      });
    };

    const onClickRedirectToAttestation = id => {
      router.push({
        name: "learning-attestations-details",
        params: { id }
      });
    };

    // Composables
    const { onUpdateCurrentPage, onUpdatePerPage } = usePaginate(
      pagination,
      getData
    );

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(selectedStatus, async () => {
      await getData();
    });

    return {
      documentTitle,
      selectedStatus,
      statusOptions,
      getData,
      onClickRedirectToAttestation,
      // useColor
      getRGBStatusColor,
      // useReadMultiple
      data,
      pagination,
      isLoading,
      // usePaginate
      onUpdateCurrentPage,
      onUpdatePerPage
    };
  }
};
</script>

<style scoped>
.test {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  margin: 0 auto;
}
</style>
