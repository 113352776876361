<template>
  <div>
    <VButton
      class="btn btn-outline-primary"
      :label="$t('app.request_attestation_upload')"
      @click="showModal"
    />

    <FormModal
      v-if="isModalVisible"
      :id="id"
      size="modal-lg"
      :is-loading="isLoading"
      @click:cancel="hideModal"
      @click:confirm="onClickSave"
    >
      <template #header>
        {{ $t("app.request_attestation_upload") }}
      </template>

      <template #content>
        <div class="mb-2">
          {{ $t("app.confirm_password_msg") }}
        </div>

        <VText
          v-model="v.password.$model"
          type="password"
          :placeholder="placeholder"
          :errors="v.password.$errors"
          @keyup.enter="onClickSave"
        />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useRequest from "@/composables/useRequest";
import useVerifyPassword from "@/composables/useVerifyPassword";
import useModal from "@/composables/useModal";
import useForm from "@/composables/useForm";
// Components
import VButton from "@/components/VButton";
import VText from "@/components/inputs/VText";
import FormModal from "@/components/modals/FormModal";

export default {
  components: {
    VButton,
    FormModal,
    VText
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["refresh"],
  setup(props, context) {
    // Inject
    const { t } = useI18n();
    const toast = inject("toast");

    // Data
    const isLoading = ref(false);
    const value = ref({ password: "" });
    const rules = ref({ password: { required } });

    // Composables
    const { placeholder, verify } = useVerifyPassword();
    const { isModalVisible, showModal, hideModal } = useModal(`#${props.id}`);
    const { request, isSuccess } = useRequest();
    const { v, onSave } = useForm(props, context, {
      value,
      rules
    });

    // Methods
    const onClickSave = async () => {
      isLoading.value = true;

      const data = await onSave();

      if (!data) {
        isLoading.value = false;
        return;
      }

      const isVerified = await verify(data.password);

      if (!isVerified) {
        isLoading.value = false;
        return;
      }

      const requestUploadResponse = await requestUpload();
      const status = requestUploadResponse?.payload?.message?.status;

      if (isSuccess(requestUploadResponse)) {
        toast.show(t("app.upload_request_success"), status);
      } else {
        toast.show(t("app.upload_request_failure"), status);
      }

      value.value.password = "";
      await v.value.$reset();
      hideModal();

      isLoading.value = false;

      context.emit("refresh");
    };

    const requestUpload = async () => {
      return await request({
        endpoint: "learning.attestations.request-upload",
        pathParams: {
          id: props.id
        }
      });
    };

    return {
      onClickSave,
      isLoading,
      placeholder,
      // useForm
      v,
      // useModal
      isModalVisible,
      showModal,
      hideModal
    };
  }
};
</script>
