<template>
  <VCard
    :title-class="hideActions || hideViewButton ? '' : 'cursor-pointer'"
    actions-class="bg-theme-5"
    @click:title="onClickRedirectToAttestation"
  >
    <template #title>
      <div class="flex items-center">
        <VImage :color="color" :name="name" class="mr-2" />

        <div class="font-medium">
          {{ name }}
        </div>
      </div>
    </template>

    <template #title-actions>
      <VChip
        v-if="resource?.status"
        :text="$t(`app.${resource?.status}`)"
        :class="getStatusColor(resource?.status)"
      />
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-2">
        <VLine
          v-if="trainingName"
          class="col-span-2"
          :label="$t('app.trainings')"
          :value="trainingName"
        />

        <VLine
          :label="$t('app.submit_by')"
          :value="$t(`app.${resource?.submit_by}`)"
        />

        <VLine
          :label="$t('app.approve_by')"
          :value="$t(`app.${resource?.approve_by}`)"
        />

        <div class="flex items-center">
          <div class="label colon">
            {{ $t("app.files", 2) }}
          </div>

          <FileItems :files="resource?.files" />
        </div>
      </div>
    </template>

    <template v-if="hasActions && !hideActions" #actions>
      <div class="flex justify-between">
        <div>
          <UploadButton
            v-if="hasUploadButton"
            :id="resource?.id"
            @refresh="$emit('refresh')"
          />

          <RequestUploadButton
            v-if="hasRequestButton"
            :id="resource?.id"
            @refresh="$emit('refresh')"
          />
        </div>

        <VButton
          v-if="!hideViewButton"
          class="btn-primary"
          :label="$t('app.view_attestation')"
          @click="onClickRedirectToAttestation"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import VImage from "@/components/VImage";
import VLine from "@/components/VLine";
import VChip from "@/components/VChip";
import VButton from "@/components/VButton";
import FileItems from "@/components/FileItems";
import UploadButton from "./UploadButton";
import RequestUploadButton from "./RequestUploadButton";

export default {
  components: {
    VCard,
    VChip,
    VButton,
    VImage,
    VLine,
    FileItems,
    UploadButton,
    RequestUploadButton
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    },
    hideViewButton: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props, context) {
    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { formatDate } = useDisplay();
    const { getText } = useTexts();
    const { getStatusColor } = useColor();

    // Computed
    const registration = computed(() => props.resource?.registration);
    const training = computed(() => registration.value?.training);
    const trainingName = computed(() => getText(training.value?.texts, "name"));
    const attestation = computed(() => props.resource?.attestation);
    const name = computed(() => getText(attestation.value?.texts, "name"));
    const color = computed(() => attestation.value?.colour);
    const status = computed(() => props.resource?.status);
    // eslint-disable-next-line
    const isStatusPending = computed(() => status.value === ALL_OPTIONS.PENDING.value);
    const submit_by = computed(() => props.resource?.submit_by);
    // eslint-disable-next-line
    const isSubmitByLearner = computed(() => submit_by.value === ALL_OPTIONS.LEARNER.value);
    // eslint-disable-next-line
    const hasUploadButton = computed(() => isSubmitByLearner.value && isStatusPending.value);
    // eslint-disable-next-line
    const isSubmitByAnyInstructor = computed(() => submit_by.value === ALL_OPTIONS.ANY_INSTRUCTOR.value);
    // eslint-disable-next-line
    const isSubmitBySpecficInstructor = computed(() => submit_by.value === ALL_OPTIONS.INSTRUCTOR.value);
    // eslint-disable-next-line
    const hasRequestButton = computed(() => (isSubmitByAnyInstructor.value || isSubmitBySpecficInstructor.value) && isStatusPending.value);
    // eslint-disable-next-line
    const hasActions = computed(() => hasRequestButton.value || hasUploadButton.value || !props.hideViewButton);

    // Methods
    const onClickRedirectToAttestation = () => {
      const id = props.resource?.id;

      if (!id) {
        return;
      }

      context.emit("click", id);
    };

    return {
      hasActions,
      name,
      color,
      trainingName,
      hasUploadButton,
      hasRequestButton,
      onClickRedirectToAttestation,
      // useDisplay
      formatDate,
      // useColor
      getStatusColor
    };
  }
};
</script>
