<template>
  <div>
    <VButton
      class="btn btn-outline-primary"
      :label="$t('app.upload_attestation')"
      @click="showModal"
    />

    <FormModal
      v-if="isModalVisible"
      :id="id"
      :is-loading="isLoading"
      @click:cancel="hideModal"
      @click:confirm="onClickSave"
    >
      <template #header>
        {{ $t("app.upload_attestation") }}
      </template>

      <template #content>
        <VFile
          v-model="v.file_ids.$model"
          :errors="v.file_ids.$errors"
          :label="$t('app.file_id')"
          class="mb-4"
        />

        <VWysiwyg
          v-model="v.learner_comments.$model"
          :errors="v.learner_comments.$errors"
          :label="$t('app.comments')"
          class="comment mb-4"
        />

        <div class="mb-2">
          {{ $t("app.confirm_password_msg") }}
        </div>

        <VText
          v-model="v.password.$model"
          type="password"
          :placeholder="placeholder"
          :errors="v.password.$errors"
          @keyup.enter="onClickSave"
        />
      </template>
    </FormModal>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useModal from "@/composables/useModal";
import useVerifyPassword from "@/composables/useVerifyPassword";
import useRequest from "@/composables/useRequest";
import useForm from "@/composables/useForm";
// Components
import VButton from "@/components/VButton";
import VFile from "@/components/inputs/VFile";
import VWysiwyg from "@/components/inputs/VWysiwyg";
import VText from "@/components/inputs/VText";
import FormModal from "@/components/modals/FormModal";

export default {
  components: {
    VButton,
    FormModal,
    VText,
    VFile,
    VWysiwyg
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["refresh"],
  setup(props, context) {
    // Inject
    const { t } = useI18n();
    const toast = inject("toast");

    // Data
    const isLoading = ref(false);
    const value = ref({
      file_ids: "",
      learner_comments: "",
      password: ""
    });
    const rules = ref({
      file_ids: { required },
      learner_comments: {},
      password: { required }
    });

    // Composables
    const { placeholder, verify } = useVerifyPassword();
    const { request, isSuccess } = useRequest();
    const { isModalVisible, showModal, hideModal } = useModal(`#${props.id}`);
    const { v, onSave } = useForm(props, context, {
      value,
      rules
    });

    // Methods
    const onClickSave = async () => {
      isLoading.value = true;

      const data = await onSave();

      if (!data) {
        isLoading.value = false;
        return;
      }

      const { password, ...payload } = data;
      const isVerified = await verify(password);

      if (!isVerified) {
        isLoading.value = false;
        return;
      }

      const requestUploadResponse = await upload(payload);
      const status = requestUploadResponse?.payload?.message?.status;

      if (isSuccess(requestUploadResponse)) {
        toast.show(t("app.upload_request_success"), status);
      } else {
        toast.show(t("app.upload_request_failure"), status);
      }

      value.value.password = "";
      await v.value.$reset();
      hideModal();

      isLoading.value = false;

      context.emit("refresh");
    };

    const upload = async data => {
      return await request({
        endpoint: "learning.attestations.upload",
        pathParams: {
          id: props.id
        },
        data
      });
    };

    return {
      placeholder,
      onClickSave,
      isLoading,
      // useForm
      v,
      // useModal
      isModalVisible,
      showModal,
      hideModal
    };
  }
};
</script>
